const openMenuBtn = document.getElementById("open-menu");
const closeMenuBtn = document.getElementById("close-menu");
const overlay = document.getElementById("overlay");
const mobileMenu = document.getElementById("mobile-menu");
const generateInput: HTMLInputElement | null = document.getElementById(
  "generate-survey-input"
) as HTMLInputElement;
const generateButton = document.getElementById("generate-survey-btn");

if (openMenuBtn && closeMenuBtn && overlay && mobileMenu) {
  const openMenu = () => {
    openMenuBtn.classList.add("hidden");
    closeMenuBtn.classList.remove("hidden");
    mobileMenu.classList.remove("hidden");
    overlay.classList.remove("opacity-0", "hidden");
    overlay.classList.add("opacity-40");
  };

  const closeMenu = () => {
    closeMenuBtn.classList.add("hidden");
    openMenuBtn.classList.remove("hidden");
    mobileMenu.classList.add("hidden");
    overlay.classList.remove("opacity-40");
    overlay.classList.add("opacity-0", "hidden");
  };

  openMenuBtn.addEventListener("click", () => {
    openMenu();
  });

  closeMenuBtn.addEventListener("click", () => {
    closeMenu();
  });

  const hideSidebarElements =
    document.getElementsByClassName("hide-sidebar-click");

  for (let i = 0; i < hideSidebarElements.length; i++) {
    hideSidebarElements[i].addEventListener("click", () => {
      closeMenu();
    });
  }
} else {
  console.log("Error: missing elements");
}

if (generateButton && generateInput) {
  generateButton.addEventListener("click", () => {
    window.location.href = `https://app.surveyassistant.ai/auth/sign-up?prompt=${generateInput.value}`;
  });
}
